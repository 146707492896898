.App {
  text-align: center;
}

@font-face {
  font-family: 'Inria';
  font-style: normal;
  font-weight: normal;
  src: local('Inria'), url('./fonts/InriaSerif-Regular.ttf') format('truetype');
}

  @font-face {
    font-family: 'InriaBold';
    font-style: normal;
    font-weight: normal;
    src: local('InriaBold'), url("./fonts/InriaSerif-Bold.ttf") format('truetype');
  }
  
@font-face{
  font-family: 'InriaLight';
  font-style: normal;
  font-weight: normal;
  src: local('InriaLight'), url('./fonts/InriaSerif-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Luminari';
  font-style: normal;
  font-weight: normal;
  src: local('Luminari'), url('./fonts/Luminari-Regular.woff') format('woff');
  }

.fontNormal {
  font-family: "Inria";
 }

 .fontBold {
  font-family: "InriaBold";
 }

 .fontLight {
  font-family: "InriaLight";
 }

.card {
  width: "";
  perspective: 999px;
} 
.card__inner { 
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card__inner.is-flipped {
  transform: rotateY(180deg);
}
.card__face {
  color: #00000000;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  overflow-x: hidden;
  border-radius: 2px;
}
.card__face--front {
  transform: none;
}

.card__face--back {
 
  transform: rotateY(180deg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.background {
  width: 100%;
  position: absolute;
  margin-top: 700px;
  z-index: -1;
  /* opacity: .8; */
}

body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subcontainer {
  position: absolute;
  margin: 0 auto;
  margin-top: 900px;
  box-shadow: 2px 2px 5px white;
  border-radius: 5px;
  background-color: white;
  display: flex;
  min-width: 45vw;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.531);
  justify-content: center;
}

.picture {
  width: 40vw;
  opacity: 1;
  margin-bottom: 30px;
}

.picturegallery {
  display: "flex";
  flex-direction: "row";
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
}

.gallerypic {
  cursor: pointer;
  width: 120px;
  margin: 10px;
  height: 120px;
  opacity: 1;
  z-index: 5;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.diapicboxa {
  position: relative;
  display: flex;
  width: 25%;
  margin: 0 auto;
  height: 350px;
  justify-content: space-between;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  flex-direction: column;
}

a:link {
  color: #161616;
  text-decoration: none;
  
}

/* visited link */
a:visited {
  color:#161616;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #6b0000;
  text-decoration: underline solid .8px;
  
  transition: all 1.9s;
  
}


/* selected link */
a:active {
  color:#161616;
  text-decoration: none;
  
  
}


/* FLIP CARD */
.flip-card {
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  position: relative;
  perspective: 9.5cm; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width:100%;
  height: 100%;
  text-align: center;
  
  transform-style: preserve-3d;
  transition: cubic-bezier(.23,1.07,.61,.84) 2.5s;
  z-index: 2;
  
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  
  transform: rotateY(360deg);
  
}



/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width:100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: rgba(187, 187, 187, 0);
  color:black;
}

/* Style the back side */
.flip-card-back {
  
  
  transform: rotateY(180deg);
  
  
}


.scroller {
  
  height: 100%;
  overflow-y: scroll; /* Add the ability to scroll */
}

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scroller::-webkit-scrollbar {
      display: none;
      
      width: 0 !important;
  }

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 3px; /* Firefox */
  overflow: -moz-scrollbars-none;
  
}
.scroller::-webkit-scrollbar {
  width: 3px;
  
  
}




