
.image-containerZ {
    display: inline-block;
    position: relative;
  } 
  
  .tooltip {
    
    background-color: rgba(255, 255, 255, 0.98);
    z-index: 200;
    border: 1px solid rgb(137, 0, 0);
    font-size: 15.5px;
    color:black;
    padding: 9px;
    border-radius: 3.5px;
    position: absolute;
    top: 0; /* Set top to 0 */
    left: 100%; /* Set left to 100% */
    width: 19vmax;
    text-align: flex-start;
    font-family: 'Inria';
    transform: translateX(10%);

    opacity: 0;
    transition: opacity .83s ease;
  }
  
  .tooltip.visible {
    opacity: 1;
    height: fit-content;
    transition: opacity .83s ease;
  }
  